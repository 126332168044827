/**
 * File header.js.
 *
 * Handles toggling the search icon for small screens
 */
( function() {



    const button = document.getElementById( 'search-toggle' );
    const searchDiv = document.getElementById( 'header-search' );

	// Return early if the button don't exist.
	if ( 'undefined' === typeof button ) {
		return;
    }

    // Return early if the search form doesn't exist.
    if ( ! searchDiv ) {
        return;
    }

	// Toggle the .toggled class and the aria-expanded value each time the button is clicked.
	button.addEventListener( 'click', function() {
        let classes = ['invisible', 'opacity-0'];
        classes.forEach(c => searchDiv.classList.toggle(c));
        button.classList.toggle('text-grey-100');

		if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
			button.setAttribute( 'aria-expanded', 'false' );
		} else {
			button.setAttribute( 'aria-expanded', 'true' );
		}
	} );

}() );



jQuery( document ).ready( function( $ ) {

	var mq = window.matchMedia('(max-width: 768px)');
	var filtersSpy = document.querySelector('#site-top-spy'); //Select our element 70px above our filter bar
	var didScroll = false;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('#masthead').outerHeight();
	var t;

	document.addEventListener('DOMContentLoaded', function() {
	$(window).on("scroll", scrollHandler);
		timedCheck();
	});


	function timedCheck() {
		t = setInterval(function() {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 250);
	}



	var scrollHandler = function(){
		didScroll = true;
	}

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
			return;
		
		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight){
			// Scroll Down
			$('#masthead').addClass('fixed-up');
		} else {
			// Scroll Up
			if(st + $(window).height() < $(document).height()) {
			$('#masthead').removeClass('fixed-up');
			}
		}
		
		lastScrollTop = st;
	}


	var filterStickyHandler = (entries) => {
	// entries is an array of observed dom nodes
	// we're only interested in the first one at [0]
	// because that's our .filters-wrapper node.
	// Here observe whether or not that node is in the viewport
	if (!entries[0].isIntersecting) {
		$( '#masthead' ).addClass('fixed'); //If not in viewport, add fixed class
	} else {
		$( '#masthead' ).removeClass('fixed'); //If in viewport, remove fixed class
	}
	}

	// create the observer
	var observer = new window.IntersectionObserver(filterStickyHandler);
	// give the observer some dom nodes to keep an eye on
	observer.observe(filtersSpy);

});