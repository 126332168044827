/**
 * File banner.js.
 *
 * Handles rotating banner on mobile devices
 */
( function() {


    const banners = document.querySelectorAll('#banners .banner');
    
    if( banners.length <= 1 ) {
	// Return early if there 1 or less banners on the page.
		return;
	}

    var currentBanner = 0, playing = true, bannerInterval = null;
    
    function nextBanner() {
        banners[currentBanner].classList.toggle( 'show' );
        currentBanner = (currentBanner+1)%banners.length;
        banners[currentBanner].classList.toggle( 'show' );
    }

    function pauseBanners() {
        playing = false;
        clearInterval(bannerInterval);
    }

    function playBanners() {
        playing = true;
        bannerInterval = setInterval(nextBanner,4000);
    }

    if (matchMedia) {
        const mq = window.matchMedia("(min-width: 767px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
    }

    // media query change
    function WidthChange(mq) {
        if (mq.matches) {
            // window width is at least 500px#
            pauseBanners();
        } else {
            // window width is less than 500px
            playBanners();
        }
        
    }

}() );